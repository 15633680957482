
import { defineComponent, ref, computed, onMounted, watch } from 'vue';
import { useMonitoringStore } from '@/store/monitoring'
import { useRouter } from 'vue-router';

export default defineComponent({
  components: {
  },
  props:{
  },
  setup(props, {emit}) {
    const patientInfo = computed(() => useMonitoringStore().getCurrPatient);

    watch(() => patientInfo.value, (newVal) => {
      // console.log('patientInfo >> ', patientInfo.value);
    });

    onMounted(() => {
    });

    return {
      patientInfo
    };
  },
});
