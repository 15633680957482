import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-39425127"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "view tdminfo" }
const _hoisted_2 = { class: "info-box" }
const _hoisted_3 = { class: "test-box" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FilteredPatients = _resolveComponent("FilteredPatients")!
  const _component_PatientInfo = _resolveComponent("PatientInfo")!
  const _component_Physic = _resolveComponent("Physic")!
  const _component_CreatineTest = _resolveComponent("CreatineTest")!
  const _component_TdmTest = _resolveComponent("TdmTest")!
  const _component_TdmRes = _resolveComponent("TdmRes")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_FilteredPatients),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_PatientInfo),
      _createVNode(_component_Physic),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_CreatineTest),
        _createVNode(_component_TdmTest)
      ]),
      _createVNode(_component_TdmRes)
    ])
  ]))
}