import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-039c3be1"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "view login" }
const _hoisted_2 = { class: "login-box" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Login = _resolveComponent("Login")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_JoinCompletePopup = _resolveComponent("JoinCompletePopup")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_Login),
      _createVNode(_component_router_link, { to: "/join" }, {
        default: _withCtx(() => [
          _createTextVNode("회원가입")
        ]),
        _: 1
      }),
      (_ctx.joinCompletePopupIsOpen)
        ? (_openBlock(), _createBlock(_component_JoinCompletePopup, { key: 0 }))
        : _createCommentVNode("", true)
    ])
  ]))
}