import axios from 'axios';
import { ref } from 'vue';
import { useLoginStore } from './store/login';
import { useUsersStore, JoinUser } from './store/users';
import moment from 'moment';
import { usePatientsStore } from './store/patients';
import { useTdmStore } from './store/tdm';
import { useLogStore } from './store/log';
import { useMonitoringStore } from './store/monitoring';

export const PROJECT_TYPE = "TDM";

export const LOGIN_SERVER_URL = '/auth'; // nginx
export const TDM_SERVER_URL = '/api';
export const PU_SERVER_URL = '/pu';

/**
 * LOGIN SERVER - login
 */
export async function login(userId: string, passwd: string) {
  try {
    const response = await axios.post(`${LOGIN_SERVER_URL}/login`, {
      "user_id": userId,
      "passwd": passwd,
      "login_type": PROJECT_TYPE
    });
    if(response.data?.result == "Already login") {
      return { state: false, data: response.data.result }
    }
    return { state: true, data: response.data }
  } catch (error: any) {
    console.error('Error fetching posts:', error);
    return { state: false, data: error.response.data.detail }
  }
}

/**
 * LOGIN SERVER - logout
 */
export async function logout() {
  try {
    const token = useLoginStore().getAccessToken;
    const userId = useUsersStore().getLoginUser.data.user_id;
    
    const response = await axios.post(`${LOGIN_SERVER_URL}/logout`, {
      "user_id": userId,
      "passwd": sessionStorage.getItem('user_passwd'),
      "login_type": PROJECT_TYPE
    },{ 
      headers: {
        Authorization: `Bearer ${token}`
      }
    },);
    return response.data;
  } catch (error) {
    console.error('Error fetching posts:', error);
  }
}

/**
 * LOGIN SERVER - getAllUserData
 */
export async function getAllUserData() {
  const token = useLoginStore().getAccessToken;
  try {
    const response = await axios.get(`${LOGIN_SERVER_URL}/getAllUserData`, {
      headers: {
        Authorization: `Bearer ${token}`
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching users:', error);
    return [];
  }
}

/**
 * LOGIN SERVER - getUserData
 */
export async function getUserData() {
  try {
    const token = useLoginStore().getAccessToken;
    const response = await axios.get(`${LOGIN_SERVER_URL}/getUserData`, {
      headers: {
        Authorization: `Bearer ${token}`
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching posts:', error);
  }
}

/**
 * LOGIN SERVER - setUserData
 */
export async function setUserData(userData: JoinUser) {
  try {
    const data = userData.data;
    const response = await axios.post(`${LOGIN_SERVER_URL}/setUserData`, {
      "user_id": data.user_id,
      "passwd": userData.passwd,
      "group": {
        "group_id": data.group.group_id,
        "level": data.group.level,
        "name": data.group.name
      },
      "name": data.name,
      "number": data.number,
      "email": data.email,
      "division": data.division
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching posts:', error);
  }
}

/**
 * LOGIN SERVER - checkRepetition
 */
export async function checkRepetition(inputId: string) {
  try {
    const response = await axios.get(`${LOGIN_SERVER_URL}/checkRepetition`, {
      params: {
        "user_id": inputId
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching posts:', error);
  }
}

/**
 * LOGIN SERVER - removeUserData
 */
export async function removeUserData(userData: JoinUser) {
  try {
    const token = useLoginStore().getAccessToken;
    const data = userData.data;
    const response = await axios.post(`${LOGIN_SERVER_URL}/removeUserData`, {
      "user_id": data.user_id,
      "passwd": userData.passwd,
      "group": {
        "group_id": data.group.group_id,
        "level": data.group.level,
        "name": data.group.name
      },
      "name": data.name,
      "number": data.number,
      "email": data.email,
      "division": data.division
    },{ 
      headers: {
        Authorization: `Bearer ${token}`
      }
    },);
    return response.data;
  } catch (error) {
    console.error('Error fetching posts:', error);
  }
}

/**
 * LOGIN SERVER - updateUserData
 */
export async function updateUserData(userData: JoinUser) {
  try {
    const token = useLoginStore().getAccessToken;
    const data = userData.data;
    const response = await axios.post(`${LOGIN_SERVER_URL}/updateUserData`, {
      "user_id": data.user_id,
      "passwd": userData.passwd,
      "group": {
        "group_id": data.group.group_id,
        "level": data.group.level,
        "name": data.group.name
      },
      "name": data.name,
      "number": data.number,
      "email": data.email,
      "division": data.division
    },{ 
      headers: {
        Authorization: `Bearer ${token}`
      }
    },);
    return response.data;
  } catch (error) {
    console.error('Error fetching posts:', error);
  }
}

/**
 * LOGIN SERVER - getGroups
 */
export async function getGroups() {
  try {
    const token = useLoginStore().getAccessToken;
    const response = await axios.get(`${LOGIN_SERVER_URL}/getGroups`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching posts:', error);
  }
}

/**
 * LOGIN SERVER - addGroups
 */
export async function addGroups(groupData: any) {
  try {
    const token = useLoginStore().getAccessToken;
    const response = await axios.post(`${LOGIN_SERVER_URL}/addGroups`, {
      "group_id": 0,
      "level": 0,
      "name": "string"
    },{ 
      headers: {
        Authorization: `Bearer ${token}`
      }
    },);
    return response.data;
  } catch (error) {
    console.error('Error fetching posts:', error);
  }
}

/**
 * LOGIN SERVER - deleteGroups
 */
export async function deleteGroups(groupData: any) {
  try {
    const token = useLoginStore().getAccessToken;
    const response = await axios.post(`${LOGIN_SERVER_URL}/deleteGroups`, {
      "group_id": 0,
      "level": 0,
      "name": "string"
    },{ 
      headers: {
        Authorization: `Bearer ${token}`
      }
    },);
    return response.data;
  } catch (error) {
    console.error('Error fetching posts:', error);
  }
}

/**
 * LOGIN SERVER - updateGroups
 * @param group_id PK. group_id를 기준으로 level, name이 업데이트 됨
 */
export async function updateGroups(groupData: any) {
  try {
    const token = useLoginStore().getAccessToken;
    const response = await axios.post(`${LOGIN_SERVER_URL}/updateGroups`, {
      "group_id": 0, 
      "level": 0,
      "name": "string"
    },{ 
      headers: {
        Authorization: `Bearer ${token}`
      }
    },);
    return response.data;
  } catch (error) {
    console.error('Error fetching posts:', error);
  }
}

/**
 * LOGIN SERVER - getSystemLogs
 */
export async function getSystemLogs() {
  try {
    const token = useLoginStore().getAccessToken;
    const response = await axios.get(`${LOGIN_SERVER_URL}/getSystemLogs`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching posts:', error);
  }
}

/**
 * LOGIN SERVER - changePasswd
 * @param user_id 사용자 ID
 * 관리자 계정으로 접속 시 사용할 수 있는 기능
 * 사용자의 비밀번호를 ID와 동일한 값으로 변경한다.
 */
export async function changePasswd(groupData: any) {
  try {
    const token = useLoginStore().getAccessToken;
    const response = await axios.post(`${LOGIN_SERVER_URL}/changePasswd`, {
      "user_id": sessionStorage.getItem('user_id')
    },{ 
      headers: {
        Authorization: `Bearer ${token}`
      }
    },);
    return response.data;
  } catch (error) {
    console.error('Error fetching posts:', error);
  }
}

/**
 * LOGIN SERVER - checkTokenExpire
 */
export async function checkTokenExpire() {
  try {
    const token = useLoginStore().getAccessToken;
    const response = await axios.get(`${LOGIN_SERVER_URL}/checkTokenExpire`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching posts:', error);
  }
}

/**
 * LOGIN SERVER - recreateToken
 */
export async function recreateToken() {
  try {
    const token = useLoginStore().getAccessToken;
    const response = await axios.get(`${LOGIN_SERVER_URL}/recreateToken`, {
      params: {
        "user_id": "string",
        "passwd": "string",
        "login_type": PROJECT_TYPE
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching posts:', error);
  }
}

/**
 * TDM SERVER - read_patientData
 */
export async function readPatientData() {
  try {
    useLoginStore().resetLogoutTimer();
    const response = await axios.post(`${TDM_SERVER_URL}/read_patientViewData`, {});
    return response.data;
  } catch (error) {
    console.error('Error fetching posts:', error);
  }
}

/**
 * TDM SERVER - tdm_predict
 */
export async function tdmPredict() {
  try {
    useLoginStore().resetLogoutTimer();
    useLogStore().startTask();
    const predictData = useTdmStore().getPredictData;
    const response = await axios.post(`${TDM_SERVER_URL}/tdm_predict`, 
      predictData
    );
    useLogStore().endTask('tdm predict 기능이 성공적으로 실행되었습니다.');
    return response.data;
  } catch (error) {
    console.error('Error fetching posts:', error);
  }
}

/**
 * TDM SERVER - tdm_recommend
 */
export async function tdmRecommend() {
  try {
    useLoginStore().resetLogoutTimer();
    useLogStore().startTask();
    const predictData = useTdmStore().getRecomData;
    const response = await axios.post(`${TDM_SERVER_URL}/tdm_recommend`, 
      predictData
    );
    useLogStore().endTask('tdm recommend 기능이 성공적으로 실행되었습니다.');
    return response.data;
  } catch (error) {
    console.error('Error fetching posts:', error);
  }
}

/**
 * TDM SERVER - sync
 */
export async function syncData() {
  try {
    useLoginStore().resetLogoutTimer();
    useLogStore().startTask();
    const response = await axios.post(`${TDM_SERVER_URL}/sync`);
    useLogStore().endTask('tdm sync 기능이 성공적으로 실행되었습니다.');
    return response.data;
  } catch (error) {
    console.error('Error fetching posts:', error);
  }
}

/**
 * TDM SERVER - logs
 */
export async function recordLog(startTime: any, endTime: any, readingTime: number, message: any) {
  const userId = useUsersStore().getLoginUser.data.user_id;

  const param = {
    user_id: userId,
    message: message,
    start_time: startTime,
    end_time: endTime,
    elapsed_time: readingTime
  }

  try {
    const response = await axios.post(`${TDM_SERVER_URL}/logs`, param, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
  } catch (error) {
    console.error('Error fetching posts:', error);
  }
}

/**
 * PU SERVER - read_patient_info
 */
export async function readPatientInfo() {
  try {
    useLoginStore().resetLogoutTimer();
    const response = await axios.post(`${PU_SERVER_URL}/read_patient_info`, {});
    return response.data;
  } catch (error) {
    console.error('Error fetching posts:', error);
  }
}

/**
 * PU SERVER - read_pu_info  
 */
export async function readPuInfoTest(ptNo: string) {
  try {
    useLoginStore().resetLogoutTimer();
    const response = await axios.post(`${PU_SERVER_URL}/read_pu_info  `,
      {
        "pt_no": ptNo
      });
    return response.data;
  } catch (error) {
    console.error('Error fetching posts:', error);
  }
}

/**
 * PU SERVER - threshold
 */
export async function readPuThreshold() {
  try {
    useLoginStore().resetLogoutTimer();
    const response = await axios.get(`${PU_SERVER_URL}/threshold`);
    return response.data[0][0];
  } catch (error) {
    console.error('Error fetching posts:', error);
  }
}

/**
 * PU SERVER - threshold update
 */
export async function updatePuThreshold(threshold: number) {
  try {
    useLoginStore().resetLogoutTimer();
    const response = await axios.put(`${PU_SERVER_URL}/threshold`, null, {
      params: {
        PU_THRESHOLD: threshold
      }
    });
  } catch (error) {
    console.error('Error fetching posts:', error);
  }
}

/**
 * PU SERVER - threshold reset
 */
export async function resetPuThreshold() {
  try {
    useLoginStore().resetLogoutTimer();
    const response = await axios.post(`${PU_SERVER_URL}/threshold/reset`);
  } catch (error) {
    console.error('Error fetching posts:', error);
  }
}