
import { defineComponent, ref, computed, onMounted, watch } from 'vue';
import TdmPreview from "./TdmPreview.vue";
import TdmPredictRes from "./TdmPredictRes.vue";
import TdmRecomRes from "./TdmRecomRes.vue";
import { useTdmStore } from '@/store/tdm'

export default defineComponent({
  components: {
    TdmPreview,
    TdmPredictRes,
    TdmRecomRes,
  },
  props:{
  },
  setup(props, {emit}) {
    const currTab = computed(() => useTdmStore().getCurrTab);
    const predictRes = computed(() => useTdmStore().getPredictRes);
    const recomRes = computed(() => useTdmStore().getRecomRes);

    const setCurrTab = (tab: string) => {
      useTdmStore().setCurrTab(tab);
      useTdmStore().initRes();
    }

    onMounted(() => {
    });

    return {
      currTab,
      setCurrTab,
      predictRes,
      recomRes,
    };
  },
});
