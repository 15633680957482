
import { defineComponent, ref, computed, onMounted, watch, onUnmounted } from 'vue';
import { useRouter } from 'vue-router';
import { useMonitoringStore } from '@/store/monitoring'
import { useIcuStore } from '@/store/icu'
import { usePageStore } from '@/store/pagenation'
import ICUBox from "../ICUBox.vue";
import lodash from 'lodash';
import moment from 'moment';

export default defineComponent({
  components: {
    ICUBox
  },
  props:{
  },
  setup(props, {emit}) {
    const router = useRouter();
    const monitoringStore = useMonitoringStore();
    const currPage = computed(() => usePageStore().getCurrPage);
    const patients = computed(() => monitoringStore.getFilteredPatients); // 전체 환자 리스트
    const searchRes = ref<any>([]); // 검색 결과에 맞는 환자 리스트
    const pageList = ref<any>([]); // 페이지에 보여지는 환자 리스트
    const searchText = ref("");
    const puThreshold = computed(() => monitoringStore.getPuThreshold);

    const movePage = () => {
      router.push({name: 'MonitoringInfo'});
    }

    
    // const setCircleColor = (puInfo: any) => {
    //   let caution = false;
    //   for(let i=0; i<puInfo.length; i++) {
    //     if(puInfo[i].PREDICTION >= 0.5) {
    //       caution = true;
    //       break;
    //     }
    //   }
    //   return caution;
    // }

    const setTotalPage = () => {
      usePageStore().setTotalPage(Math.ceil((searchRes.value.length)/16));
    }

    const setPatientData = (patient: any, tab: string) => {
      monitoringStore.setCurrPatient(lodash.cloneDeep(patient));
      monitoringStore.setCurrTab(tab);
      movePage();
    }

    const setPage = () => {
      pageList.value = [];
      for(let i=(currPage.value-1)*16; i<currPage.value*16; i++) {
        if(searchRes.value[i]) {
          pageList.value.push(searchRes.value[i]);
        }
      }
      setTotalPage();
    }

    const searchPatient = () => {
      searchRes.value = [];
      if(searchText.value == "") {
        searchRes.value = patients.value;
        setPage();
      } else {
        for(let i=0; i<patients.value.length; i++) {
          let log = patients.value[i];
          let flag = false;

          let inputData = {
            WD_DEPT_CD: log.WD_DEPT_CD,
            PT_NO: log.PT_NO,
            PT_NM: log.PT_NM,
            AGE: log.AGE,
            HEIGHT: log.HEIGHT,
            WEIGHT: log.WEIGHT,
            SEX_TP_CD: log.SEX_TP_CD,
            PREDICTION: log.PREDICTION,
          }

          if(log["PT_NO"].toString().indexOf(searchText.value) !== -1) {
            flag = true;
            let replaceText = "";
            let index = log["PT_NO"].toString().indexOf(searchText.value);
            let originText = log["PT_NO"].toString();
            replaceText = originText.substr(index, searchText.value.length);
          }
          if(log["PT_NM"].toString().indexOf(searchText.value) !== -1) {
            flag = true;
            let replaceText = "";
            let index = log["PT_NM"].toString().indexOf(searchText.value);
            let originText = log["PT_NM"].toString();
            replaceText = originText.substr(index, searchText.value.length);
          }

          if(flag){
            searchRes.value.push(inputData);
          }
        }
        setPage();
        usePageStore().initPage();
      }
    }

    const currSort = ref<any>("")

    const sortPatient = (value:any) => {
      function resetSort(){
        searchRes.value.sort(function(a:any,b:any){
          return a.PT_NO < b.PT_NO ? -1 : a.PT_NO > b.PT_NO ? 1 : 0;
        })
      }

      switch(value){
        case 'unit':
          // 내림차순이면, 한 번 이미 눌렀다는 뜻으로, 두번 누르면 오름차순 정렬
          if(currSort.value == 'unitDesc'){
            currSort.value = 'unitAsc'
            searchRes.value.sort(function(a:any,b:any){
              return a.WD_DEPT_CD < b.WD_DEPT_CD ? -1 : a.WD_DEPT_CD > b.WD_DEPT_CD ? 1 : 0;
            })
          }
          // 오름차순이면, 두 번 이미 눌렀다는 뜻으로, 세번 누르면 초기화
          else if(currSort.value == 'unitAsc'){
            currSort.value = ''
            resetSort();
          }
          // 처음 눌렀을때 내림차순 정렬
          else{
            currSort.value = 'unitDesc'
            searchRes.value.sort(function(a:any,b:any){
              return a.WD_DEPT_CD > b.WD_DEPT_CD ? -1 : a.WD_DEPT_CD < b.WD_DEPT_CD ? 1 : 0;
            })
          }
          break;
        case 'number': 
          if(currSort.value == 'numberDesc'){
            currSort.value = 'numberAsc'
            searchRes.value.sort(function(a:any,b:any){
              return b.PT_NO - a.PT_NO;
            })
          }
          else if(currSort.value == 'numberAsc'){
            currSort.value = ''
            resetSort();
          }
          else{
            currSort.value = 'numberDesc'
            searchRes.value.sort(function(a:any,b:any){
              return a.PT_NO - b.PT_NO;
            })
          }
          break;
        case 'name': 
          if(currSort.value == 'nameDesc'){
            currSort.value = 'nameAsc'
            searchRes.value.sort(function(a:any,b:any){
              return a.PT_NM < b.PT_NM ? -1 : a.PT_NM > b.PT_NM ? 1 : 0;
            })
          }
          else if(currSort.value == 'nameAsc'){
            currSort.value = ''
            resetSort();
          }
          else{
            currSort.value = 'nameDesc'
            searchRes.value.sort(function(a:any,b:any){
              return a.PT_NM > b.PT_NM ? -1 : a.PT_NM < b.PT_NM ? 1 : 0;
            })
          }
          break;
        case 'age': 
          if(currSort.value == 'ageDesc'){
            currSort.value = 'ageAsc'
            searchRes.value.sort(function(a:any,b:any){
              return b.AGE - a.AGE ;
            })
          }
          else if(currSort.value == 'ageAsc'){
            currSort.value = ''
            resetSort();
          }
          else{
            currSort.value = 'ageDesc'
            searchRes.value.sort(function(a:any,b:any){
              return a.AGE - b.AGE;
            })
          }
          break;
        case 'gender': 
          if(currSort.value == 'genderDesc'){
            currSort.value = 'genderAsc'
            searchRes.value.sort(function(a:any,b:any){
              return a.SEX_TP_CD < b.SEX_TP_CD ? -1 : a.SEX_TP_CD > b.SEX_TP_CD ? 1 : 0;
            })
          }
          else if(currSort.value == 'genderAsc'){
            currSort.value = ''
            resetSort();
          }
          else{
            currSort.value = 'genderDesc'
            searchRes.value.sort(function(a:any,b:any){
              return a.SEX_TP_CD > b.SEX_TP_CD ? -1 : a.SEX_TP_CD < b.SEX_TP_CD ? 1 : 0;
            })
          }
          break;
        case 'pu': 
          if(currSort.value == 'puDesc'){
            currSort.value = 'puAsc'
            searchRes.value.sort(function(a:any,b:any){
              return a.PREDICTION < b.PREDICTION ? -1 : a.PREDICTION > b.PREDICTION ? 1 : 0;
            })
          }
          else if(currSort.value == 'puAsc'){
            currSort.value = ''
            resetSort();
          }
          else{
            currSort.value = 'puDesc'
            searchRes.value.sort(function(a:any,b:any){
              return a.PREDICTION > b.PREDICTION ? -1 : a.PREDICTION < b.PREDICTION ? 1 : 0;
            })
          }
          break;
      }
      setPage();
      usePageStore().initPage();
    }

    watch(() => patients.value, () => {
      searchPatient();
    });


    watch(() => currPage.value, () => {
      setPage();
    });

    onMounted(async () => {
      searchPatient();
      await monitoringStore.readPuThreshold();

      const focusableDiv = document.querySelector('.focusable') as HTMLDivElement;
      const inputField = focusableDiv.querySelector('input') as HTMLInputElement;

      inputField.addEventListener('focus', () => {
        focusableDiv.classList.add('focused');
      });

      inputField.addEventListener('blur', () => {
        focusableDiv.classList.remove('focused');
      });
    });

    onUnmounted(() => {
      useIcuStore().togglePopup(false);
    });

    return {
      patients,
      searchRes,
      setPatientData,
      searchText,
      searchPatient,
      pageList,
      moment,
      sortPatient,
      currSort,
      puThreshold
    };
  },
});
