
import { defineComponent, ref, computed, onMounted, watch } from 'vue';
import { useTdmStore } from '@/store/tdm'
import { usePatientsStore } from '@/store/patients'
import { usePopupStore } from '@/store/popup'
// import { tdmPredict } from '@/api'

interface PatientData {
  AGE: number;
  HEIGHT: number;
  WEIGHT: number;
  SEX_TP_CD: string;
  DRST_OWH_QTY: string;
  IMND_CNT: number;
  INTERVAL: string;
  CREATININE_RSLT: number;
  VNC_RSLT: number;
  LOADING: number;
  TDM_INTERVAL: number;
}

export default defineComponent({
  components: {
  },
  props:{
  },
  setup(props, {emit}) {
    const currTab = computed(() => useTdmStore().getCurrTab);
    const loading = computed(() => useTdmStore().getLoading);

    const patientData = ref<PatientData[]>([]);
    const currPatient = computed(() => usePatientsStore().getCurrPatient);
    const editPatient = computed(() => usePatientsStore().getEditPatient);
    const predictRes = computed(() => useTdmStore().getPredictRes);
    const chartOptions = ref();

    const setLoading = (loading: number) => {
      useTdmStore().setLoading(loading);
    }

    const xAxisData = ref<string[]>([]);
    const yAxisData = ref<(number | { value: number;  itemStyle: { color: string; borderColor: string; borderWidth: number }; label: {color: string } })[]>([]);

    const tdmPredict = async                                                                                        (): Promise<any> => {
      // 값을 제대로 입력했을 경우 true로 return됨
      const { result, length } = usePopupStore().checkTdmPredict();
      if(result){
        if(length < 3) { // 약제 투여 횟수가 3회 미만일 경우 경고 문구 띄움
          usePopupStore().togglePopup('tdmPrAlert', true);
          useTdmStore().setShowPredictAlertState(true);
        }
        patientData.value = [];

        for(let i=0; i<editPatient.value.VNC.length; i++) {
          xAxisData.value.push("TDM"+(i+1));
        }
        xAxisData.value.push("Prediction");
      
        
        editPatient.value.data.forEach((row) => {
          row.forEach((item) => {
            const vncResult = Number(item.VNC_RSLT);
            if (vncResult !== 0) {
              yAxisData.value.push(vncResult);
            }

            patientData.value.push({
              AGE: editPatient.value.AGE,
              HEIGHT: editPatient.value.HEIGHT,
              WEIGHT: editPatient.value.WEIGHT,
              SEX_TP_CD: editPatient.value.SEX_TP_CD,
              DRST_OWH_QTY: item.DRST_OWH_QTY,
              IMND_CNT: editPatient.value.IMND_CNT,
              INTERVAL: item.INTERVAL.toString(),
              CREATININE_RSLT: item.CREATININE_RSLT,
              VNC_RSLT: vncResult,
              LOADING: loading.value,
              TDM_INTERVAL: item.TDM_INTERVAL,
            });
          });
        });

        await useTdmStore().predict(patientData.value);
        yAxisData.value.push(
          {
            value: Number((predictRes.value.last_reg).toFixed(2)),
            itemStyle: { color: '#FF5560', borderColor: '#FF5560', borderWidth: 10 },
            label: { color: '#FF5560' },
          }
        );  

        const option = {
          title: {
            show: false,
          },
          grid: {
            height: '80%',
            top: '30px',
            bottom: 0,
          },
          xAxis: {
            data: xAxisData.value,
            axisTick: {
              alignWithLabel: true,
            },
            axisLabel: {
              interval: 0,
              color: '#ffffff',
              fontSize: 14,
              lineHeight: 40
            },
          },
          yAxis: {
            axisLabel:{
              color: '#ffffff',
              fontSize: 14,
            },
            splitLine: {
              lineStyle: {
                color: '#46495E'
              }
            }
          },
          series: [
            {
              type: 'line',
              data: yAxisData.value,
              symbolSize: 8,
              lineStyle: {
                color: '#0B8EF0',
                width: 3
              },
              label: {
                show: true,
                color: "#0B8EF0",
              },
              markArea: {
                silent: true,
                data: [
                  [
                    {
                      name: 'Subtherapeutic range',
                      itemStyle: {
                        color: '#FBF2D2'
                      },
                      label:{
                        position: 'insideTopLeft',
                        color: '#888AA5'
                      },
                      yAxis: '0'
                    },
                    {
                      yAxis: '15'
                    }
                  ],
                  [
                    {
                      name: 'Therapeutic range',
                      itemStyle: {
                        color: '#DCEEF8'
                      },
                      label:{
                        position: 'insideTopLeft',
                        color: '#888AA5'
                      },
                      yAxis: '15'
                    },
                    {
                      yAxis: '20'
                    }
                  ],
                  [
                    {
                      name: 'Toxin range',
                      itemStyle: {
                        color: '#F6D9E1'
                      },
                      label:{
                        position: 'insideTopLeft',
                        color: '#888AA5'
                      },
                      yAxis: '20'
                    },
                    {
                      yAxis: '100'
                    }
                  ]
                ]
              },
            },
          ],
        };
        useTdmStore().setChartOptions(option);
      }
    }


    const tdmRecom = async (): Promise<any> => {
      patientData.value = [];
      
      if(editPatient.value.data[0].length > 0) {
        for(let i=0; i<editPatient.value.data.length; i++) {
          for(let j=0; j<editPatient.value.data[i].length; j++) {
            patientData.value.push({
              "AGE": editPatient.value.AGE,
              "HEIGHT": editPatient.value.HEIGHT,
              "WEIGHT": editPatient.value.WEIGHT,
              "SEX_TP_CD": editPatient.value.SEX_TP_CD,
              "DRST_OWH_QTY": editPatient.value.data[i][j].DRST_OWH_QTY,
              "IMND_CNT": editPatient.value.IMND_CNT,
              "INTERVAL": (editPatient.value.data[i][j].INTERVAL).toString(),
              "CREATININE_RSLT": editPatient.value.data[i][j].CREATININE_RSLT,
              "VNC_RSLT": Number(editPatient.value.data[i][j].VNC_RSLT),
              "LOADING": loading.value,
              "TDM_INTERVAL": editPatient.value.data[i][j].TDM_INTERVAL
            });
          }
        }
      } else {
        patientData.value.push({
          "AGE": editPatient.value.AGE,
          "HEIGHT": editPatient.value.HEIGHT,
          "WEIGHT": editPatient.value.WEIGHT,
          "SEX_TP_CD": editPatient.value.SEX_TP_CD,
          "DRST_OWH_QTY": "0",
          "IMND_CNT": editPatient.value.IMND_CNT,
          "INTERVAL": "0",
          "CREATININE_RSLT": editPatient.value.CREATININE[editPatient.value.CREATININE.length-1].CREATININE_RSLT,
          "VNC_RSLT": 0,
          "LOADING": loading.value,
          "TDM_INTERVAL": 0
        });
      }
      await useTdmStore().recommend(patientData.value);
    }
    
    onMounted(() => {
    });

    return {
      currTab,
      setLoading,
      loading,
      tdmPredict,
      predictRes,
      tdmRecom,
      chartOptions,
      editPatient
    };
  },
});
