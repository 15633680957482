import { defineStore } from 'pinia'
import { computed, ref } from 'vue';
import { usePatientsStore } from './patients';

interface PopupState { // popup name
  joinFail: boolean;
  joinComplete: boolean;
  logoutAlert: boolean;
  tdmPrError: boolean;
  tdmPrAlert: boolean;
}

export const usePopupStore = defineStore('popup', {
  state: () => ({
    joinFail: false,
    joinComplete: false,
    resultList: false,
    logoutAlert: false,
    tdmPrError: false,
    tdmPrAlert: false,
  }),
  actions: {
    initPopupState() {
      this.joinFail = false;
      this.joinComplete = false;
      this.resultList = false;
      this.logoutAlert = false;
      this.tdmPrError = false;
      this.tdmPrAlert = false;
    },
    togglePopup(popupName: keyof PopupState, opened: boolean) {
      if (Object.keys(this.$state).includes(popupName)) {
        this[popupName] = opened;
      } else {
        console.warn(`No such popup: ${popupName}`);
      }
    },
    checkTdmPredict(){
      this.tdmPrError = false;
      const editPatient = usePatientsStore().getEditPatient.data;
      const checkArray = editPatient[editPatient.length - 1];
      const res = ref(true);

      // 추가한 값이 있는지 확인
      const notAdd = checkArray.some(item => Number(item.VNC_RSLT) > 0);
      if(notAdd){
        console.log(1);
        this.tdmPrError = true;
        res.value = false;
      }
      
      // 행을 하나 추가했다 삭제한 경우, 새 배열이 이미 들어가있으므로, 해당 배열에 값이 존재하는지 아닌지 확인
      if(checkArray[0] == undefined){
        this.tdmPrError = true;
        res.value = false;
      }
      
      // 추가한 값 중 빈 값이 있는지 확인
      checkArray.some((item, index) => {
        // const isBlank = (index < checkArray.length - 1 && (Number(item.INTERVAL) === 0) || Number(item.DRST_OWH_QTY) === 0 || item.ACT_TIME === "")
        const isBlank = ref(true);
        
        if(index != 0 || editPatient.length > 1) {
          isBlank.value = (Number(item.INTERVAL) === 0 || Number(item.DRST_OWH_QTY) === 0 || item.ACT_TIME === "")
        } else {
          isBlank.value = (Number(item.DRST_OWH_QTY) === 0 || item.ACT_TIME === "")
        }

        if(isBlank.value){
          this.tdmPrError = true;
          res.value = false;
        }
      });

      return { result: res.value, length: checkArray.length };
    },
  },
  getters: {
    getIsOpen: (state) => (popupName: keyof PopupState) => {
      if (popupName in state) {
        return state[popupName];
      } else {
        console.warn(`No such popup: ${popupName}`);
        return false;
      }
    },
  },
});