import { createApp } from 'vue'
// import store from './store'
import { createPinia } from "pinia";
import App from './App.vue'
import router from './router';
import { setupCalendar, DatePicker } from 'v-calendar';
import VueKonva from 'vue-konva';
import VueVideoPlayer from '@videojs-player/vue';
import VueHighcharts from 'vue3-highcharts';
// import { CanvasRenderer } from 'echarts/renderers'
// import { PieChart, BarChart } from 'echarts/charts'
// import { TooltipComponent, LegendComponent, GridComponent } from 'echarts/components'
// import { use } from 'echarts/core'
// use([CanvasRenderer, PieChart, BarChart, TooltipComponent, LegendComponent, GridComponent])

const app = createApp(App);
app.use(VueKonva);
app.use(VueVideoPlayer);
app.use(VueHighcharts);
app.use(createPinia()).use(router).use(setupCalendar, {}).mount('#app');
app.component('VDatePicker', DatePicker);