
import { defineComponent, ref, computed, onMounted, watch, nextTick } from 'vue';
import { usePatientsStore } from '@/store/patients'
import { useTdmStore } from '@/store/tdm'
import { useRouter } from 'vue-router';
import lodash from 'lodash';

export default defineComponent({
  components: {
  },
  props:{
  },
  setup(props, {emit}) {
    const router = useRouter();
    const patientStore = usePatientsStore();
    const patients = computed(() => patientStore.getFilteredPatients);
    const currPatient = computed(() => patientStore.getCurrPatient);
    const selectedPatientIdx = ref();

    const movePage = (page: string) => {
      router.push({name: page});
    }

    const setPatientData = (patient: any) => {
      if(patient.CREATININE.length > 0){
        useTdmStore().initRes();
        patientStore.setCurrPatient(lodash.cloneDeep(patient));
        findSelectedPatientIdx();
      }
    }

    const findSelectedPatientIdx = () => {
      patients.value.forEach((patient, idx) => {
        if(patient.PT_NO === currPatient.value.PT_NO) {
          selectedPatientIdx.value = idx;
        }
      })
    }

    watch(() => patients.value, (newVal) => {
      // patientStore.initCurrPatient();
    });

    // watch(() => currPatient.value, (newVal) => {
    //   console.log('currPatient.value>> ', currPatient.value);
    //   findSelectedPatientIdx();
    // });
    
    onMounted(async () => {
      await nextTick();
      findSelectedPatientIdx();
    });

    return {
      patients,
      setPatientData,
      selectedPatientIdx,
      movePage
    };
  },
});
