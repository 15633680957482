
import { defineComponent, computed, onMounted } from 'vue';
import Login from "../components/login/Login.vue";
import { usePopupStore } from '@/store/popup'
import JoinCompletePopup from "../components/popup/JoinCompletePopup.vue";

export default defineComponent({
  components: {
    Login,
    JoinCompletePopup
  },
  setup() {
    const popupStore = usePopupStore();
    const joinCompletePopupIsOpen = computed(() => popupStore.getIsOpen('joinComplete'));

    onMounted(() => {
    });

    return {
      joinCompletePopupIsOpen
    };
  },
});
