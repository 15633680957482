import { resolveComponent as _resolveComponent, createVNode as _createVNode, vModelText as _vModelText, withKeys as _withKeys, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-77615afa"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "component login" }
const _hoisted_2 = {
  key: 0,
  class: "error_msg"
}
const _hoisted_3 = {
  key: 1,
  class: "error_msg"
}
const _hoisted_4 = {
  key: 2,
  class: "error_msg"
}
const _hoisted_5 = {
  key: 3,
  class: "error_msg"
}
const _hoisted_6 = {
  key: 4,
  class: "error_msg"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Logo = _resolveComponent("Logo")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Logo, { class: "logo" }),
    _createElementVNode("div", null, [
      _withDirectives(_createElementVNode("input", {
        type: "text",
        class: "input__box",
        placeholder: "아이디",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.inputId) = $event)),
        onKeyup: [
          _cache[1] || (_cache[1] = _withKeys(($event: any) => (_ctx.login()), ["enter"])),
          _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.hideErrorMsg && _ctx.hideErrorMsg(...args)))
        ],
        id: "loginId"
      }, null, 544), [
        [_vModelText, _ctx.inputId]
      ]),
      _withDirectives(_createElementVNode("input", {
        type: "password",
        class: "input__box",
        placeholder: "비밀번호",
        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.inputPassword) = $event)),
        onKeyup: [
          _cache[4] || (_cache[4] = _withKeys(($event: any) => (_ctx.login()), ["enter"])),
          _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.hideErrorMsg && _ctx.hideErrorMsg(...args)))
        ],
        id: "loginPass"
      }, null, 544), [
        [_vModelText, _ctx.inputPassword]
      ]),
      (_ctx.incorrectError)
        ? (_openBlock(), _createElementBlock("h3", _hoisted_2, "* 아이디 또는 비밀번호가 일치하지 않습니다."))
        : (_ctx.idError)
          ? (_openBlock(), _createElementBlock("h3", _hoisted_3, "* 아이디를 입력해주세요."))
          : (_ctx.pwError)
            ? (_openBlock(), _createElementBlock("h3", _hoisted_4, "* 비밀번호를 입력해주세요."))
            : (_ctx.alreadyLoginError)
              ? (_openBlock(), _createElementBlock("h3", _hoisted_5, "* 이미 로그인 되어있는 사용자입니다."))
              : (_ctx.loginCountError)
                ? (_openBlock(), _createElementBlock("h3", _hoisted_6, "* 로그인 시도 횟수가 초과되어 계정이 비활성화되었습니다."))
                : _createCommentVNode("", true)
    ]),
    _createElementVNode("button", {
      class: "button__blue login-btn",
      onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.login()))
    }, "로그인")
  ]))
}