import { defineStore } from 'pinia';
import { readPatientInfo, readPuInfoTest, readPuThreshold, updatePuThreshold, resetPuThreshold } from '../api';
import { useIcuStore, ICU } from '@/store/icu';

interface Patient {
  PT_NO: string;
  PT_NM: string;
  WD_DEPT_CD: string;
  AGE: number;
  HEIGHT: number;
  WEIGHT: number;
  SEX_TP_CD: string;
  PREDICTION: number;
  PU_INFO: any;
}

export const useMonitoringStore = defineStore('monitoring', {
  state: () => {
    const initData: Patient = {
      PT_NO: '',
      PT_NM: '',
      WD_DEPT_CD: '',
      AGE: 0,
      HEIGHT: 0,
      WEIGHT: 0,
      SEX_TP_CD: '',
      PREDICTION: 0,
      PU_INFO: []
    };

    return {
      initData,
      patients: [] as Patient[],
      filteredPatients: [] as Patient[],
      currPatient: { ...initData },
      editPatient: { ...initData },
      chartData: [] as any[],
      currTab: 'pu',
      puThreshold: 0
    };
  },
  actions: {
    async getPatientData(): Promise<void> {
      try {
        const res = await readPatientInfo();
        this.patients = res.data;
        await this.getPuInfo();

        // 필터링된 환자 목록 설정
        this.setFilteredPatients();
      } catch (error) {
        console.error('Error fetching patients:', error);
      }
    },
    async getPuInfo() {
      try {
        for(let i=0; i<this.patients.length; i++) {
          const res = await readPuInfoTest(this.patients[i].PT_NO);
          this.patients[i].PU_INFO = res.data;
        }
      } catch (error) {
        console.error('Error fetching:', error);
      } 
    },
    setFilteredPatients() {
      try {
        this.filteredPatients = [];
        const icuStore = useIcuStore();
        const icuCodes = icuStore.getIcuList
          .filter((icu: ICU) => icu.checked)
          .map((icu: ICU) => icu.icu);

        this.filteredPatients = this.patients.filter(patient => 
          icuCodes.includes(patient.WD_DEPT_CD)
        );
      } catch (error) {
        console.error('Error filtering patients:', error);
      }
    },
    setCurrPatient(patient: Patient) {
      this.currPatient = { ...patient };
    },
    setChartData(data: any) {
      this.chartData = data;
    },
    setCurrTab(tab: string) {
      this.currTab = tab;
    },
    async readPuThreshold(): Promise<void> {
      try {
        const res = await readPuThreshold();
        this.puThreshold = res;
      } catch (error) {
        console.error('Error:', error);
      }
    },
    async updatePuThreshold(threshold: number): Promise<void> {
      try {
        await updatePuThreshold(threshold);
        await this.readPuThreshold();
      } catch (error) {
        console.error('Error:', error);
      }
    },
    async resetPuThreshold(): Promise<void> {
      try {
        await resetPuThreshold();
        await this.readPuThreshold();
      } catch (error) {
        console.error('Error:', error);
      }
    }
  },
  getters: {
    getPatients(state): Patient[] {
      return state.patients;
    },
    getFilteredPatients(state): Patient[] {
      return state.filteredPatients;
    },
    getCurrPatient(state): Patient {
      return state.currPatient;
    },
    getChartData(state): any[] {
      return state.chartData;
    },
    getCurrTab(state): any {
      return state.currTab;
    },
    getPuThreshold(state): any {
      return state.puThreshold;
    }
  },
});
