
import { defineComponent, ref, computed, onMounted, watch } from 'vue';
import PatientList from "@/components/monitoring/PatientList.vue";
import Pagenation from "@/components/Pagenation.vue";

export default defineComponent({
  components: {
    PatientList,
    Pagenation
  },
  props:{
  },
  setup(props, {emit}) {

    onMounted(async () => {
    });

    return {
    };
  },
});
