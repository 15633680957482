
import { defineComponent, ref, onMounted, onUnmounted, nextTick, computed, watch } from 'vue';
import * as echarts from 'echarts';
import { useMonitoringStore } from '@/store/monitoring';
import { useUsersStore } from '../../store/users'
import moment from 'moment';
import lodash from 'lodash';

export default defineComponent({
  setup() {
    const monitoringStore = useMonitoringStore();
    const userStore = useUsersStore();
    const loginUser = computed(() => userStore.getLoginUser);
    const currPatient = computed(() => monitoringStore.getCurrPatient);
    const currTab = computed(() => monitoringStore.getCurrTab);
    const puThreshold = computed(() => monitoringStore.getPuThreshold);
    const threshold = ref(lodash.cloneDeep(puThreshold.value));

    const updateThreshold = async (): Promise<void> => {
      await monitoringStore.updatePuThreshold(threshold.value);
      processPatientData();
    }

    const resetThreshold = async (): Promise<void> => {
      await monitoringStore.resetPuThreshold();
      processPatientData();
    }

    const setCurrTab = (tab: string) => {
      monitoringStore.setCurrTab(tab);
    }

    let myChart: echarts.ECharts | null = null;

    const xAxisData = ref<string[]>([]);
    const yAxisData = ref<(number | { value: number;  itemStyle: { color: string; borderColor: string; borderWidth: number }; label: {color: string } })[]>([]);
    
    let dataZoomStart = 0;
    let dataZoomEnd = 0;

    const drawChart = () => {
      const chartDom = document.getElementById('chart');
      if (!chartDom) {
        console.warn('Chart DOM not found.');
        return;
      }

      if (myChart) {
        myChart.dispose();
      }

      if (xAxisData.value.length === 0 || yAxisData.value.length === 0) {
        console.warn('No data available for chart');
        return;
      }

      myChart = echarts.init(chartDom);

      const visibleDataCount = 10;
      const totalDataCount = xAxisData.value.length;

      if (totalDataCount === 0) {
        console.warn('No data to display on the chart.');
        return;
      }

      dataZoomStart = (100 - ((visibleDataCount-1) / totalDataCount) * 100);
      dataZoomEnd = 100;

      const option = {
        title: '',
        tooltip: {
          trigger: 'axis',
        },
        grid: {
          width: "95.5%",
          left: '3%',
          top: '5%'
        },
        xAxis: {
          type: 'category',
          data: xAxisData.value,
          axisTick: {
            alignWithLabel: true,
          },
          axisLabel: {
            interval: 0,
            color: '#ffffff',
            fontSize: 14,
          },
        },
        yAxis: {
          type: 'value',
          max: 100,
          axisLabel:{
            color: '#ffffff',
            fontSize: 14,
          },
          splitLine: {
            lineStyle: {
              color: '#46495E'
            }
          }
        },
        dataZoom: [
          {
            type: 'slider',
            start: dataZoomStart,
            end: dataZoomEnd,
            zoomLock: true,
            brushSelect: false,
          },
        ],
        series: [{
          type: 'line',
          data: yAxisData.value,
          symbolSize: 8,
          lineStyle: {
            color: '#68ADE6',
            width: 3
          },
          label: {
            show: true,
            fontSize: 15,
            fontWeight: "bold",
            color: "#68ADE6",
          }
        }],
      };

      myChart.setOption(option);

      myChart.on('dataZoom', (event: any) => {
        const totalDataPoints = xAxisData.value.length;
        let startIndex = Math.round((event.start / 100 ) * (totalDataPoints -1))
        let endIndex = Math.round((event.end / 100 ) * (totalDataPoints -1))

        if (endIndex - startIndex + 1 > visibleDataCount) {
          endIndex = startIndex + visibleDataCount - 1;
        }

        if (endIndex - startIndex + 1 < visibleDataCount) {
          startIndex = Math.max(0, endIndex - visibleDataCount + 1);
        }
        
        const selectedData = currPatient.value?.PU_INFO.slice(startIndex, endIndex + 1).map((item: any) => {
          return {
            PU_DATE: moment(item.PU_DATE).format('YYYY.MM.DD HH:mm'),
            RESP_RATE: item.RESP_RATE,
            SEDATION: item.SEDATION,
            DIARRHEA: item.DIARRHEA,
            RESTRAINT: item.RESTRAINT,
            REPOSITION: item.REPOSITION,
            HCO3: item.HCO3,
          };
        });
        monitoringStore.setChartData(selectedData);
      });
    };

    const processPatientData = () => {
      if (currPatient.value?.PU_INFO && Array.isArray(currPatient.value.PU_INFO)) {
        xAxisData.value = currPatient.value.PU_INFO.map((info: any) => moment(info.PU_DATE).format('YYYY.MM.DD HH:mm'));
        yAxisData.value = currPatient.value.PU_INFO.map((info: any) => {
          const predictionValue = Number((info.PREDICTION * 100).toFixed(0));
    
          if (predictionValue >= puThreshold.value) {
            return {
              value: predictionValue,
              itemStyle: {
                color: '#FF4450',
                borderColor: '#FF4450',
                borderWidth: 10,
              },
              label: {
                color: '#FF4450',
              },
            };
          }
          return predictionValue;
        });
        drawChart(); 
      } else {
        console.warn('No PU_INFO data available in currPatient.');
      }
    };

    const initChart = () => {
      processPatientData();
      if (myChart) {
        myChart.dispatchAction({
          type: 'dataZoom',
          start: dataZoomStart,
          end: dataZoomEnd,
        });
      }
    }

    watch(() => currPatient.value?.PU_INFO, (newVal, oldVal) => {
      if (newVal !== oldVal && newVal != undefined) {
        initChart();
      }
    });

    watch(() => puThreshold.value, (newVal) => {
      threshold.value = lodash.cloneDeep(puThreshold.value);
    });

    const handleResize = () => {
      if (myChart) myChart.resize();
    };

    onMounted(async () => {
      await nextTick();
      await monitoringStore.readPuThreshold();
      initChart();
      window.addEventListener('resize', handleResize);
    });

    onUnmounted(() => {
      if (myChart) myChart.dispose();
      window.removeEventListener('resize', handleResize);
    });

    return {
      currTab,
      setCurrTab,
      puThreshold,
      updateThreshold,
      threshold,
      resetThreshold,
      loginUser
    };
  },
});

