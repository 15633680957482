
import { defineComponent, computed, onMounted, watch, nextTick } from 'vue';
import { useTdmStore } from '@/store/tdm';
import * as echarts from 'echarts';

export default defineComponent({
  setup() {
    const predictRes = computed(() => useTdmStore().getPredictRes);
    const chartOptions = computed(() => useTdmStore().getChartOptions);
    const showPredictAlert = computed(() => useTdmStore().getShowPredictAlert);

    const initRes = () => {
      useTdmStore().initRes();
    };

    const drawChart = () => {
      const chartDom = document.getElementById('tdm_predict_chart');
      if (chartDom) {
        const myChart = echarts.init(chartDom);
        myChart.setOption(chartOptions.value); // chartOptions 사용
      } else {
        console.error('Chart DOM not found');
      }
    };

    watch(
      () => chartOptions.value,
      (newVal) => {
        if (newVal) {
          nextTick().then(() => {
            drawChart(); // chartOptions 변경 시 차트 다시 그림
          });
        }
      }
    );

    onMounted(async () => {
      await nextTick();
      if (chartOptions.value) {
        drawChart(); // 초기 마운트 시 차트 그리기
      }
    });

    return {
      predictRes,
      chartOptions,
      initRes,
      showPredictAlert
    };
  },
});
