
import { defineComponent, ref, computed, onMounted, watch } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { useMenuStore } from '@/store/menu'
import { useIcuStore } from '@/store/icu'

export default defineComponent({
  components: {
  },
  props:{
  },
  setup(props, {emit}) {
    const router = useRouter();
    const menuList = computed(() => useMenuStore().getMenuList);

    const moveMenu = (menu: any, idx: number) => {
      useMenuStore().setCurrMenu(idx);
      useIcuStore().initIcuCheckedState();
      router.push({name: menu.name});
    }

    onMounted(() => {
      const path = useRoute().path;
      if(path.includes('tdm')) {
        useMenuStore().setCurrMenu(0);
      } else if(path.includes('monitoring')) {
        useMenuStore().setCurrMenu(1);
      }
    })

    return {
      menuList,
      moveMenu
    };
  },
});
