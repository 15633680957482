
import { defineComponent, ref, computed, onMounted, watch } from 'vue';
import { useRoute } from 'vue-router';
import { useIcuStore } from '@/store/icu'
import { usePatientsStore } from '@/store/patients'
import { usePageStore } from '@/store/pagenation'
import lodash from 'lodash';
import { useMonitoringStore } from '@/store/monitoring';

export default defineComponent({
  components: {
  },
  props:{
  },
  setup(props, {emit}) {
    const route = useRoute(); // 현재 경로 정보를 가져오는 훅
    const icuList = computed(() => useIcuStore().getIcuList);
    const icuTemp = ref(lodash.cloneDeep(icuList.value));
    const icuPopupIsOpen = computed(() => useIcuStore().getIsOpen);
    const btnActive = ref(true);
    const allSelected = ref(true);
    
    const text = computed(() => {
      return btnActive.value&&!allSelected.value ? '중환자실 적용' : '중환자실 전체';
    });

    const toggleIcuBox = () => {
      useIcuStore().togglePopup(!icuPopupIsOpen.value);
      icuTemp.value = lodash.cloneDeep(icuList.value);
    }

    const setIcu = () => {
      if(btnActive.value) {
        useIcuStore().setIcu(icuTemp.value);
        useIcuStore().togglePopup(false);
        if(route.path.includes('tdm')) {
          usePatientsStore().setFilteredPatients();
        } else {
          useMonitoringStore().setFilteredPatients();
        }
        usePageStore().initPage();

        const count = ref(0);
        for(let i=0; i<icuTemp.value.length; i++) {
          if(icuTemp.value[i].checked) {
            count.value++;
          }
        }
        if(count.value == 6) {
          allSelected.value = true;
        } else {
          allSelected.value = false;
        }
      }
    }

    const selectAll = () => {
      for(let i=0; i<icuTemp.value.length; i++) {
        icuTemp.value[i].checked = true;
      }
      btnActive.value = true;
    }

    const checkIcu = (icu: any) => {
      icu.checked = !icu.checked;

      const count = ref(0);
      for(let i=0; i<icuTemp.value.length; i++) {
        if(!icuTemp.value[i].checked) {
          count.value++;
        }
      }
      if(count.value == 6) {
        btnActive.value = false;
      } else {
        btnActive.value = true;
      }
    }

    const init = () => {
      usePatientsStore().setFilteredPatients();
      useMonitoringStore().setFilteredPatients();
    }

    onMounted(() => {
      init();
    });

    return {
      icuList,
      toggleIcuBox,
      icuPopupIsOpen,
      setIcu,
      icuTemp,
      selectAll,
      btnActive,
      checkIcu,
      allSelected,
      text
    };
  },
});
