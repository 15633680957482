
import { defineComponent, ref, computed, onMounted, watch, nextTick } from 'vue';
import { useMonitoringStore } from '@/store/monitoring'
import { useRouter } from 'vue-router';
import lodash from 'lodash';

export default defineComponent({
  components: {
  },
  props:{
  },
  setup(props, {emit}) {
    const router = useRouter();
    const monitoringStore = useMonitoringStore();
    const patients = computed(() => monitoringStore.getFilteredPatients);
    const currPatient = computed(() => monitoringStore.getCurrPatient);
    const selectedPatientIdx = ref();

    const movePage = (page: string) => {
      router.push({name: page});
    }

    const setPatientData = async (patient: any): Promise<void> => {
      monitoringStore.setCurrPatient(lodash.cloneDeep(patient));
      findSelectedPatientIdx();
    }

    const findSelectedPatientIdx = () => {
      patients.value.forEach((patient, idx) => {
        if(patient.PT_NO === currPatient.value.PT_NO) {
          selectedPatientIdx.value = idx;
        }
      })
    }

    watch(() => patients.value, (newVal) => {
      // monitoringStore.initCurrPatient();
    });
    
    onMounted(async () => {
      await nextTick();
      findSelectedPatientIdx();
    });

    return {
      patients,
      setPatientData,
      selectedPatientIdx,
      movePage
    };
  },
});
