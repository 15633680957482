import { defineStore } from 'pinia'
import { login, logout } from '../api';
import { usePopupStore } from './popup';

function getAccessToken(): string {
  const accessToken = sessionStorage.getItem('access_token');
  if (accessToken !== null && accessToken !== undefined) {
    return accessToken;
  }
  return "";
}
function setSessionStorage(item: string, data: any) {
  sessionStorage.setItem(item, data);
}
function removeSessionStorage(item: string) {
  sessionStorage.removeItem(item);
}

let logoutTimer: ReturnType<typeof setTimeout> | null = null;


// 현재 로그인 되어있는 유저의 정보를 저장
export const useLoginStore = defineStore('login', {
  state: () => ({
    token: getAccessToken()
  }),
  actions: {
    async login(inputId: string, inputPw: string): Promise<any> {
      try {
        const res = await login(inputId, inputPw);
        if (res?.state) {
          this.token = res.data.access_token;
          setSessionStorage('access_token', res.data.access_token);
          setSessionStorage('user_passwd', inputPw);
          this.startLogoutTimer(); // 타이머 시작
        }
        return res;
      } catch (error) {
        console.error('Error logging in:', error);
      }
      return false;
    },
    async logout() {
      if (logoutTimer) {
        clearTimeout(logoutTimer); // 타이머 초기화
        logoutTimer = null;
      }

      const res = await logout();
      this.token = "";
      sessionStorage.clear();
      return res?.result || false;
    },
    startLogoutTimer() {
      if (logoutTimer) clearTimeout(logoutTimer); // 기존 타이머 초기화
      logoutTimer = setTimeout(() => {
        this.logout();
        usePopupStore().togglePopup('logoutAlert', true);
        console.log('Logged out due to inactivity.');
      }, 30 * 60 * 1000); // 30분(600,000ms) 타이머 설정
    },
    resetLogoutTimer() {
      if (this.token) {
        // console.log('로그아웃 타이머 리셋');
        this.startLogoutTimer(); // 타이머 리셋
      }
    },
  },
  getters: {
    getAccessToken(state): string {
      return state.token;
    }
  },
});