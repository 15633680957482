import { defineStore } from 'pinia';
import { ref } from 'vue';
import { readPatientData, syncData } from '../api';
import { useIcuStore, ICU } from '@/store/icu';
import moment from 'moment';

interface DataItem {
  CREATININE_DTE: string;
  CREATININE_RSLT: number;
  DRST_OWH_QTY: string;
  INTERVAL: string;
  ACT_TIME: string;
  ORD_NM: string;
  VNC_DTE: string;
  VNC_RSLT: string;
  ADD: boolean;
  TDM_INTERVAL: number;
}

interface Patient {
  PT_NO: string;
  PT_NM: string;
  WD_DEPT_CD: string;
  AGE: number;
  HEIGHT: number;
  WEIGHT: number;
  SEX_TP_CD: string;
  IMND_CNT: number;
  data: Array<Array<DataItem>>;
  CREATININE: Array<DataItem>;
  VNC: Array<DataItem>;
}

export const usePatientsStore = defineStore('patients', {
  state: () => {
    const initData: Patient = {
      PT_NO: '',
      PT_NM: '',
      WD_DEPT_CD: '',
      AGE: 0,
      HEIGHT: 0,
      WEIGHT: 0,
      SEX_TP_CD: '',
      IMND_CNT: 0,
      data: [[]] as Array<Array<DataItem>>,
      CREATININE: [],
      VNC: []
    };

    return {
      initData,
      patients: ref<Patient[]>([]),
      filteredPatients: ref<Patient[]>([]),
      currPatient: { ...initData },
      editPatient: { ...initData }
    };
  },
  actions: {
    async getPatientData(): Promise<void> {
      try {
        const res = await readPatientData();
        this.patients = res.map((item:any) => ({
          ...item,
          data: item.data.map((innerArray:any) => 
            innerArray.map((dataItem:any) => ({
              ...dataItem,
              ADD: false,
            }))
          )
        }));
        this.setFilteredPatients();
      } catch (error) {
        console.error('Error fetching patients:', error);
      }
    },
    setFilteredPatients() {
      const icuStore = useIcuStore();
      const icuCodes = icuStore.getIcuList
        .filter((icu: ICU) => icu.checked)
        .map((icu: ICU) => icu.icu);
      this.filteredPatients = this.patients.filter(patient => {
        return icuCodes.includes(patient.WD_DEPT_CD);
      });
    },
    setCurrPatient(patient: Patient) {
      this.currPatient = { ...patient };
    },
    setEditPatient(patient: Patient) {
      this.editPatient = { ...patient };
    },
    addEditPatient() {
      const editData = this.editPatient.data;
      const currData = this.currPatient.data;

      // 마지막 배열과 배열 내 마지막 항목 가져오기
      const lastEditArray = editData[editData.length - 1] || [];
      const lastEditItem = lastEditArray[lastEditArray.length - 1];
      const lastCurrArray = currData[currData.length - 1] || [];
      const lastCurrItem = lastCurrArray[lastCurrArray.length - 1];

      // 새로운 배열 추가 조건 확인
      if (lastEditArray.length !== 0 && !lastEditItem?.ADD) {
        const lastVNCDate = new Date(this.editPatient.VNC[this.editPatient.VNC.length - 1].VNC_DTE);
        const lastCurrDate = new Date(lastCurrItem?.ACT_TIME);

        const isNewArrayRequired =
          (lastVNCDate.getTime() > lastCurrDate.getTime()) &&
          currData.length === editData.length;

        if (isNewArrayRequired) {
          editData.push([]);
        }
      }

      // 마지막 배열의 날짜 계산
      const lastArray = editData[editData.length - 1];
      const date = ref(new Date());
      const yesterday = new Date(date.value);
      yesterday.setDate(date.value.getDate() - 1);

      let isEmptyArray = false;

      if (lastArray.length > 0) {
        date.value = new Date(lastArray[lastArray.length - 1].ACT_TIME);
      } else {
        const previousArray = editData[editData.length - 2];
        if (previousArray?.length > 0) {
          date.value = new Date(previousArray[previousArray.length - 1].ACT_TIME);
        } else {
          date.value = yesterday;
          isEmptyArray = true;
        }
      }

      // 다음 날 및 시간 간격 계산
      const specificDate = new Date(date.value);
      const nextDay = new Date(specificDate);
      nextDay.setDate(specificDate.getDate() + 1);

      const differenceInHours = isEmptyArray ? 0 : (nextDay.getTime() - specificDate.getTime()) / (1000 * 60 * 60);

      // 새로운 항목 추가
      if (Array.isArray(lastArray)) {
        lastArray.push({
          CREATININE_DTE: "",
          CREATININE_RSLT: Number(this.editPatient.CREATININE[this.editPatient.CREATININE.length - 1].CREATININE_RSLT),
          DRST_OWH_QTY: "0",
          INTERVAL: differenceInHours.toString(),
          ACT_TIME: moment(nextDay).format('YYYY-MM-DD HH:mm'),
          ORD_NM: "",
          VNC_DTE: "",
          VNC_RSLT: "",
          ADD: true,
          TDM_INTERVAL: 0,
        });
      }
    },
    deleteEditPatient(i: number, j: number) {
      if (this.editPatient.data[i] && this.editPatient.data[i][j]) {
        this.editPatient.data[i].splice(j, 1);
      }
    },
    initCurrPatient() {
      this.currPatient = { ...this.initData };
    },
    async syncData() {
      try {
        const res = await syncData();
      } catch (error) {
        console.error('Error fetching patients:', error);
      }
    }
  },
  getters: {
    getPatients(state): Patient[] {
      return state.patients;
    },
    getFilteredPatients(state): Patient[] {
      return state.filteredPatients;
    },
    getCurrPatient(state): Patient {
      return state.currPatient;
    },
    getEditPatient(state): Patient {
      return state.editPatient;
    }
  }
});