
import { defineComponent, ref, computed, onMounted, watch } from 'vue';
import { usePatientsStore } from '@/store/patients'
import moment from 'moment';
import lodash from 'lodash';
import { DatePicker } from 'v-calendar';
import 'v-calendar/style.css';
import "@/css/style.css";

export default defineComponent({
  components: {
    // DatePicker
  },
  props:{
    modelValue: {
      type: [Number, String],
      default: ''
    },
    isEditable: {
      type: Boolean,
      default: true
    }
  },
  setup(props, {emit}) {
    const patientInfo = computed(() => usePatientsStore().getCurrPatient);
    const editPatient = computed(() => usePatientsStore().getEditPatient);
    const focusedActTimeValue = ref();
    const focusedIntervalValue = ref();

    const masks = ref({
      inputDateTime24hr: 'YYYY-MM-DD HH:mm',
    });

    const focusIn = (i: any, j: any) => {
      editPatient.value.data[i][j].DRST_OWH_QTY = (editPatient.value.data[i][j].DRST_OWH_QTY).replace('mg', '')
    }
    const focusOut = (i: any, j: any) => {
      const unit = 'mg';
      editPatient.value.data[i][j].DRST_OWH_QTY = editPatient.value.data[i][j].DRST_OWH_QTY + unit;
    }

    const addItem = () => {
      usePatientsStore().addEditPatient();
    }

    const removeItem = (i: number, j: number) => {
      if(editPatient.value.data[i][j+1]) {
        if(i == 0 && j == 0) {
          editPatient.value.data[i][j+1].INTERVAL = '0';
        } else {
          editPatient.value.data[i][j+1].INTERVAL = (Number(editPatient.value.data[i][j+1].INTERVAL) + Number(editPatient.value.data[i][j].INTERVAL)).toString();
        }
      }
      usePatientsStore().deleteEditPatient(i, j);
    }

    const calculateTimeDifference = (date1Str: string, date2Str: string): number | null => {
      const date1 = new Date(date1Str);
      const date2 = new Date(date2Str);

      // 유효한 날짜인지 확인
      if (isNaN(date1.getTime()) || isNaN(date2.getTime())) {
        // console.error('유효하지 않은 날짜 형식:', date1Str, date2Str);
        return null;
      }

      // 밀리초 차이를 시간 단위로 변환
      const differenceInMillis = date2.getTime() - date1.getTime();
      return differenceInMillis / (1000 * 60 * 60); // 시간으로 변환
    };

    const focusOutEvtActTime = (i: number, j: number) => {
      console.log('evt focusOutEvtActTime');
      const currArrayActTime = editPatient.value.data[i][j].ACT_TIME;

      if (currArrayActTime == null) {
        editPatient.value.data[i][j].ACT_TIME = focusedActTimeValue.value;
      } else {
        for (let k = j; k < editPatient.value.data[i].length; k++) {
          const previousArray = (k === 0 && editPatient.value.data[i - 1]) || editPatient.value.data[i];

          if (previousArray?.length > 0) {
            const previousActTime = (k === 0) ? previousArray[0].ACT_TIME : previousArray[k - 1].ACT_TIME;
            const currentActTime = editPatient.value.data[i][k].ACT_TIME;
            const differenceInHours = calculateTimeDifference(previousActTime, currentActTime);

            if (differenceInHours !== null) {
              // console.log(`시간 단위 차이: ${(differenceInHours).toFixed(2)}시간`);
              editPatient.value.data[i][k].INTERVAL = ((parseFloat(differenceInHours.toFixed(2))).toString());
            } else {
              editPatient.value.data[i][k].INTERVAL = "0";
            }
          } else {
            editPatient.value.data[i][k].INTERVAL = "0";
          }
        }
      }
    };

    const focusOutEvtInterval = (i: number, j: number) => {
      console.log('evt focusOutEvtInterval');
      if(j !== 0) {
        const currArrayInterval = editPatient.value.data[i][j].INTERVAL;
        console.log('currArrayInterval >> ', currArrayInterval);

        if(currArrayInterval == "" || focusedIntervalValue.value == undefined) {
          editPatient.value.data[i][j].INTERVAL = focusedIntervalValue.value;
        } else {
          for (let k = j; k < editPatient.value.data[i].length; k++) {
            const previousArray = editPatient.value.data[i][k-1];
            editPatient.value.data[i][k].ACT_TIME = (addHoursToDate(previousArray.ACT_TIME, Number(editPatient.value.data[i][k].INTERVAL))).toString()
          }
        }
      }
    }

    const addHoursToDate = (dateStr: string, hoursToAdd: number) => {
      let date = new Date(dateStr);
      
      const hours = Math.floor(hoursToAdd);
      const minutes = Math.round((hoursToAdd - hours) * 60); 

      date.setHours(date.getHours() + hours);
      date.setMinutes(date.getMinutes() + minutes);

      return date;
    };

    const updateInterval = (event: any, physic: any, isLastItem: boolean) => {
      const value = event.target.value;
      if (isLastItem) {
        physic.INTERVAL = '-';
      } else {
        physic.INTERVAL = value === '-' ? 0 : Number(value);
      }
    }

    const localValue = ref<string>(props.modelValue?.toString() || '');

    // modelValue가 변경되면 localValue도 업데이트
    watch(() => props.modelValue, (newValue) => {
      localValue.value = newValue?.toString() || '';
    });

    // 숫자만 허용하도록 필터링하는 메서드
    const filterNumericInput = (event: Event) => {
      const input = event.target as HTMLInputElement;
      // 숫자만 남기고 나머지 제거
      input.value = input.value.replace(/[^0-9]/g, '');
      localValue.value = input.value;
      emit('update:modelValue', input.value);
    };

    // localValue를 반영하여 최종 값 설정
    watch(localValue, (newVal) => {
      emit('update:modelValue', newVal);
    });

    // isEditable을 computed로 변환하여 사용
    const computedIsEditable = computed(() => props.isEditable);

    watch(() => patientInfo.value, (newVal) => {
      usePatientsStore().setEditPatient(lodash.cloneDeep(newVal));
    });

    onMounted(() => {
      usePatientsStore().setEditPatient(lodash.cloneDeep(patientInfo.value));
    });

    return {
      patientInfo,
      editPatient,
      moment,
      focusIn,
      focusOut,
      addItem,
      masks,
      updateInterval,
      removeItem,
      localValue,
      filterNumericInput,
      computedIsEditable,
      focusedActTimeValue,
      focusedIntervalValue,
      focusOutEvtActTime,
      focusOutEvtInterval
    };
  },
});
