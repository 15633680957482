import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-29c8e96a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "component tdmres" }
const _hoisted_2 = { class: "btn__box" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TdmPredictRes = _resolveComponent("TdmPredictRes")!
  const _component_TdmRecomRes = _resolveComponent("TdmRecomRes")!
  const _component_TdmPreview = _resolveComponent("TdmPreview")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("button", {
        class: _normalizeClass(["button__trans_blue", {'active':_ctx.currTab=='predict'}]),
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.setCurrTab('predict')))
      }, "TDM 예측 결과", 2),
      _createElementVNode("button", {
        class: _normalizeClass(["button__trans_blue", {'active':_ctx.currTab=='recom'}]),
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.setCurrTab('recom')))
      }, "TDM 추천 결과", 2)
    ]),
    (_ctx.currTab=='predict' && _ctx.predictRes !== '')
      ? (_openBlock(), _createBlock(_component_TdmPredictRes, { key: 0 }))
      : (_ctx.currTab=='recom' && _ctx.recomRes !== '')
        ? (_openBlock(), _createBlock(_component_TdmRecomRes, { key: 1 }))
        : (_openBlock(), _createBlock(_component_TdmPreview, { key: 2 }))
  ]))
}